import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { BlogPostStyle } from "../components/BlogPostStyle";
import Content, { HTMLContent } from "../components/Content";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { BlogWrapper } from "../components/BlogLayout";

export const PrivacyPolicyTemplate = ({ title, content, contentComponent }) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <SEO title={title} />
      <BlogPostStyle>
        <PostContent content={content} />
      </BlogPostStyle>
    </>
  );
};

PrivacyPolicyTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const PrivacyPolicy = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogWrapper>
        <PrivacyPolicyTemplate
          contentComponent={HTMLContent}
          title={post.frontmatter.title}
          content={post.html}
        />
      </BlogWrapper>
    </Layout>
  );
};

PrivacyPolicy.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrivacyPolicy;

export const privacyPolicyQuery = graphql`
  query PrivacyPolicy($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
